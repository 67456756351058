import { css } from '@emotion/react'
import {
  Avatar,
  colorTokens,
  downloadFile,
  getFileDisplayName,
  Icon,
  spacing,
  Spinner,
  useAsyncCallback,
} from '@orus.eu/pharaoh'
import { Row } from '@orus.eu/pharaoh/src/components/rows'
import { useState, type ReactElement } from 'react'
import { type ContractDocumentDescription } from '../../lib/contract-util'
import { sendMessage } from '../../lib/tracking/tracking'

export type ContractDocumentDownloadRowProps = {
  doc: ContractDocumentDescription
  disabled?: boolean
}

export function ContractDocumentDownloadRow(props: ContractDocumentDownloadRowProps): ReactElement {
  const { doc, disabled, ...otherProps } = props

  const [downloadInProgress, setDownloadInProgress] = useState<boolean>(false)

  const handleDownload = useAsyncCallback(async () => {
    setDownloadInProgress(true)

    let actionPromise: Promise<void>
    switch (doc.method) {
      case 'get':
        sendMessage({
          event: 'download_contract_certificate_document',
          document_type: doc.fileType,
        })
        actionPromise = downloadFile(doc.url, doc.fileName)
        break
      case 'post':
        actionPromise = doc.onClick()
        break
    }
    try {
      await actionPromise
    } finally {
      setDownloadInProgress(false)
    }
  }, [setDownloadInProgress, doc])

  return (
    <Row
      variant="basic"
      css={css`
        padding: ${spacing[50]};
      `}
      title={getFileDisplayName(doc.fileName)}
      titleVariant={'body2'}
      leftAvatar={<Avatar icon="file-lines-regular" size="30" color={colorTokens['color-fg-base-active-inverse']} />}
      rightAvatar={downloadInProgress ? <Spinner size="30" /> : <Icon icon="arrow-down-to-line-regular" />}
      rightIcon={'arrow-down-to-line-regular'}
      onClick={handleDownload}
      disabled={downloadInProgress || disabled}
      {...otherProps}
    />
  )
}
